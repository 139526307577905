import React from "react";
import { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Carousel } from "react-bootstrap";
import { Accordion } from 'react-bootstrap';
import logoicon from '../images/logo-icon.png';
import homeaboutimg1 from '../images/home-about-img1.svg';
import homenftimg1 from '../images/home-nft-img-1.svg';
import homeexchangeimg1 from '../images/home-exchange-img-1.svg';
import homedevelopmentimg1 from '../images/home-development-img-1.svg';
import homepaymentimg1 from '../images/home-payment-img-1.svg';
import homesocialmediaimg1 from '../images/home-social-media-img1.svg';
import homeepayimg1 from '../images/home-epay-img1.svg';
import homeotcimg1 from '../images/home-otc-img1.svg';
import homefaqimg1 from '../images/home-faq-img1.svg';
import tech1 from '../images/tech1.png';
import tech2 from '../images/tech2.png';
import tech3 from '../images/tech3.png';
import tech4 from '../images/tech4.png';
import tech5 from '../images/tech5.png';
import tech6 from '../images/tech6.png';
import tech7 from '../images/tech7.png';
import tech8 from '../images/tech8.png';
import tech9 from '../images/tech9.png';
import tech10 from '../images/tech10.png';
import tech11 from '../images/tech11.png';
import tech12 from '../images/tech12.png';
import chooseimg1 from '../images/choose-img1.png';
import chooseimg2 from '../images/choose-img2.png';
import chooseimg3 from '../images/choose-img3.png';

function Home() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <>
    <section className="bannerSec">
      <div className="container">
        <div className="row">
        <div className="col-md-12">
          <h6>Eclipton</h6>
          <h1>On <span>Innovative</span> <br />Solution Provider <img src={logoicon} style={{ width: '60%' }} /></h1>  
          <p>Welcome to our platform, your premier destination for innovative technology solutions. As a leading provider in the industry, we specialize in delivering cutting-edge technological advancements tailored to meet your specific needs. With our expertise and commitment to innovation, we strive to empower businesses and individuals alike with the latest in technology. Explore our range of solutions and discover how we can revolutionize your approach to technology.</p>          
          <a href="#" className="primaryBtn me-2">Talk to our Experts</a>
          <a href="#" className="secondaryBtn ms-2">Free Demo</a>
        </div>
        </div>
      </div>
    </section>
    <section className="counterSec">
      <div className="container">
        <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-6">
              <h4>{counterOn && <CountUp start={0} end={5} duration={2} delay={1} />} <span>+ Years</span></h4>   
              <p>Experience in Innovative Software Development</p>  
            </div> 
            <div className="col-lg-3 col-md-6 col-6">
              <h4>{counterOn && <CountUp start={0} end={60} duration={2} delay={0.8} />} <span>+</span></h4>   
              <p>Employees in Globally in<br /> Four Continents</p>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <h4>{counterOn && <CountUp start={0} end={200} duration={2} delay={0} />} <span>+</span></h4>
              <p>Projects Delivered</p>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <h4>{counterOn && <CountUp start={0} end={80} duration={2} delay={0.6} />} <span>+</span></h4>  
              <p>Global brands</p> 
            </div>   
          </div>  
        </ScrollTrigger>  
      </div>   
    </section>
    <section className="aboutSec">
      <div className="container">
        <div className="row">
        <div className="col-lg-6">
          <img src={homeaboutimg1} style={{ width: '70%' }} />
        </div>
        <div className="col-lg-6 pt-2">
          <h6>Know About Us</h6>
          <h2>The <span>Eclipton</span> Company</h2>
          <p>Welcome to Eclipton Technologies, a renowned leader in technology solutions across four continents. With a robust track record of successfully delivering over 500 projects, we are proud to be at the forefront of innovation in the global market. Our journey began with a vision to redefine technology solutions, and today, we continue to expand our reach globally, providing cutting-edge services to clients worldwide. </p>
          <p>At Eclipton Technologies, we are dedicated to excellence, creativity, and client satisfaction. Our team of experts is committed to delivering tailored solutions that drive success and propel businesses forward in the ever-evolving digital landscape. Join us on our journey as we shape the future of technology together.</p>
          <div className="buttonSec">
            <a href="#" className="primaryBtn me-2">Get Connect</a>
            <a href="#" className="secondaryBtn ms-2">Free Demo</a>
          </div>
        </div>
        </div>
      </div>
    </section>
    <section className="home-servicesSec">
      <div className="container">
        <div className="row">
          <h6>Services</h6>
          <Tabs
            defaultActiveKey="development"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="development" title="Blockchain Software Development">
            <div className="row">
                <div className="col-lg-6">
                  <h2 className="pt-4"><span>Blockchain Software</span> Development</h2>
                  <p>Leveraging the latest technologies and best practices, our expert team specializes in crafting bespoke software solutions tailored to meet the unique needs of our clients. From web and mobile applications to enterprise software, we ensure seamless functionality and exceptional user experiences.</p>
                  <div className="buttonSec">
                    <a href="#" className="primaryBtn me-2">Talk to our Experts</a>
                    <a href="#" className="secondaryBtn ms-2">View More</a>
                  </div>
                </div>
                <div className="col-lg-6 text-center">
                  <img src={homedevelopmentimg1} style={{ width: '70%' }} />
                </div>
              </div>
            </Tab>
            <Tab eventKey="Consultation" title="Consultation">
              <div className="row">
                <div className="col-lg-6 text-center">
                  <img src={homepaymentimg1} style={{ width: '72%' }} />
                </div>
                <div className="col-lg-6">
                  <h2 className="pt-4">Consultation</h2>
                  <p>With our deep industry expertise and strategic insights, we offer consultancy services to guide businesses through their digital transformation journey. Whether it's formulating a technology roadmap, optimizing existing processes, or exploring innovative solutions, our consultants provide valuable guidance to drive business growth and success.</p>
                  <div className="buttonSec">
                    <a href="#" className="primaryBtn me-2">Talk to our Experts</a>
                    <a href="#" className="secondaryBtn ms-2">View More</a>
                  </div>
                </div>                
              </div>
            </Tab>
          </Tabs>  
        </div>
      </div>
    </section>
    <section className="home-productSec">
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-12">
            <h6 className="mb-2">Eclipton technologies solutions and products</h6>
            <div className="row">
              <div className="col-md-12">
                <p>At Eclipton Technologies, we pride ourselves on delivering cutting-edge solutions and products that empower businesses to thrive in today's dynamic digital landscape. Our diverse range of offerings includes:</p>
              </div>
            </div>
          </div>
        </div>
          <Carousel fade className="pt-5">
            <Carousel.Item>
              <div className="row">
                <div className="col-lg-6 text-center">
                  <img src={homesocialmediaimg1} style={{ width: '70%' }} />
                </div>
                <div className="col-lg-6">
                  <h2 className="pt-2">Software <span>Development</span></h2>
                  <p>Leveraging the latest technologies and best practices, our expert team specializes in crafting bespoke software solutions tailored to meet the unique needs of our clients. From web and mobile applications to enterprise software, we ensure seamless functionality and exceptional user experiences.</p>
                  <div className="buttonSec">
                    <a href="#" className="primaryBtn me-2">Talk to our Experts</a>
                    <a href="#" className="secondaryBtn ms-2">View More</a>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
            <div className="row">
                <div className="col-lg-6 text-center">
                  <img src={homeepayimg1} style={{ width: '60%' }} />
                </div>
                <div className="col-lg-6">
                  <h2 className="pt-2"><span>Payment Gateway</span> Development</h2>
                  <p>Facilitating secure and efficient online transactions, our payment gateway development services enable businesses to accept payments seamlessly across various channels. We design and implement robust payment solutions that prioritize security, reliability, and scalability.</p>
                  <div className="buttonSec">
                    <a href="#" className="primaryBtn me-2">Talk to our Experts</a>
                    <a href="#" className="secondaryBtn ms-2">View More</a>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row">
                <div className="col-lg-6 text-center">
                  <img src={homeotcimg1} style={{ width: '60%' }} />
                </div>
                <div className="col-lg-6">
                  <h2 className="pt-2">Blockchain<span> Software</span></h2>
                  <p>As pioneers in blockchain technology, we provide comprehensive services for building blockchain-based solutions. From developing decentralized applications (DApps) to implementing smart contracts and blockchain integration, we help businesses harness the power of distributed ledger technology for enhanced security and transparency.</p>
                  <div className="buttonSec">
                    <a href="#" className="primaryBtn me-2">Talk to our Experts</a>
                    <a href="#" className="secondaryBtn ms-2">View More</a>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row">
                <div className="col-lg-6 text-center">
                  <img src={homeotcimg1} style={{ width: '60%' }} />
                </div>
                <div className="col-lg-6">
                  <h2 className="pt-2">Consultation</h2>
                  <p>With our deep industry expertise and strategic insights, we offer consultancy services to guide businesses through their digital transformation journey. Whether it's formulating a technology roadmap, optimizing existing processes, or exploring innovative solutions, our consultants provide valuable guidance to drive business growth and success.</p>
                  <div className="buttonSec">
                    <a href="#" className="primaryBtn me-2">Talk to our Experts</a>
                    <a href="#" className="secondaryBtn ms-2">View More</a>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>      
    </section>
    <section className="home-whychooseSec">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h6>Our Solutions</h6>
            <h2>Why <span>Choose</span> Us</h2>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="home-whychooseSec-box">
              <span><img src={chooseimg1} /></span>
              <h4>Instant Connect</h4>
              <p> The words which don't look even slightly  If you are going to use a passage of Lorem  need tobe  sure there isn't anything.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="home-whychooseSec-box whychooseSec-box-two">
              <img src={chooseimg2} />
              <h4>24/7 Support</h4>
              <p> The words which don't look even slightly  If you are going to use a passage of Lorem  need tobe  sure there isn't anything.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="home-whychooseSec-box whychooseSec-box-three">
              <img src={chooseimg3} />
              <h4>Detailed Statics</h4>
              <p> The words which don't look even slightly  If you are going to use a passage of Lorem  need tobe  sure there isn't anything.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default Home;
