import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../images/eclipTech_logo.svg';
import './style.css';

function Navigation() {

  const [navSize, setnavSize] = useState("10rem");
  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#000") : setnavColor("transparent");
    window.scrollY > 10 ? setnavSize("5rem") : setnavSize("10rem");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (   
    <>
    <Navbar expand="lg"  fixed="top" style={{
      backgroundColor: navColor,
      height: navSize,
      transition: "all 1s"
    }}>
      <Container>
        <Navbar.Brand href="/" className="ml-auto"><img src={logo} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            <NavLink className="nav-link" to="/">Home
            </NavLink>
            <NavLink className="nav-link" to="/">Marketplace development</NavLink>
            <NavLink className="nav-link" to="/">Blockchain Software Development</NavLink>
            <NavLink className="nav-link" to="/">Payment Software Development</NavLink>
            <a className="navBtn" href="#contactus">Contact Us</a>
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>  
    <a href="#formsection" className="expertSec">Ask an Expert</a>
    </> 
  );
}

export default Navigation;
