import React from "react";
import nftbannerimg1 from '../images/nft-banner-img1.svg';
import nftdevimg1 from '../images/nft-dev-img1.svg';
import nftstartimg1 from '../images/nft-start-img1.svg';
import nftdomainicon1 from '../images/nft-domain-icon1.svg';
import nftlendingicon1 from '../images/nft-lending-icon1.svg';
import nftipoicon1 from '../images/nft-ipo-icon1.svg';
import nftphotosicon1 from '../images/nft-photos-icon1.svg';
import nftfashionicon1 from '../images/nft-fashion-icon1.svg';
import nftgamesicon1 from '../images/nft-games-icon1.svg';
import nftrealestateicon1 from '../images/nft-real-estate-icon1.svg';
import nftsportsicon1 from '../images/nft-sports-icon1.svg';
import nftmusicicon1 from '../images/nft-music-icon1.svg';
import nftarticon1 from '../images/nft-art-icon1.svg';
import chooseimg1 from '../images/choose-img1.png';

function Nftmarketplace() {
  return (
    <>
    <section className="pageBanner">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-6">
            <h6>Eclipton</h6>
            <h1 className="font-weight-light"><span>NFT Marketplace</span> Development</h1>
            <p>At Eclipton technologies, we with the top level experts offer the best NFT solutions possible to bring out your project in flying colors and lead the trending market.</p>
            <div className="buttonSec">
              <a href="#" className="primaryBtn me-2">Talk to our Experts</a>
              <a href="#" className="secondaryBtn ms-2">Free Demo</a>
            </div>
          </div>
          <div className="col-lg-6 text-center">
            <img src={nftbannerimg1} style={{ width: '75%' }} />
          </div>          
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 pb-3">
            <h6>Development</h6>
            <h2>Captivating <span>NFT Development</span></h2>            
          </div>
          <div className="col-lg-6 col-md-12 text-center">
            <img src={nftdevimg1} style={{ width: '85%' }} /> 
          </div>
          <div className="col-lg-6 col-md-12">
            <p className="pt-2">NFTs or non-fungible tokens are cryptographic digital assets on a blockchain that contain unique identification codes and metadata that distinguish the tokens from one another. Unlike cryptocurrencies, they cannot be traded or interchanged at equivalence but can be utilized as a source for commercial transactions. They represent unique collectibles or items, which can include anything from gaming assets to paintings and digital art.</p>
            <p>At Eclipton technologies, we with the top level experts offer the best NFT solutions possible to bring out your project in flying colors and lead the trending market. Our NFTs are built to eliminate middlemen and connect artists and creators with audiences or for identity management and build new markets.</p>
            <div className="buttonSec">
              <a href="#" className="primaryBtn me-2">Get Connect</a>
              <a href="#" className="secondaryBtn ms-2">Free Demo</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="nftadvantageSec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h6>Advantage</h6>
            <h2>Advantages Of <span>NFTs</span></h2>    
          </div>
          <div className="col-md-6">
            <p className="rightBorder">This is a network of shared platforms the size of an NFT, with no buyer having the ability to edit the data afterwards.</p>
          </div>
          <div className="col-md-6">
            <p className="leftBorder">NFT developers can produce a limited number of non-fungible tokens, and frequently update the tokens increase interest.</p>
          </div>
          <div className="col-md-6">
            <p className="rightBorder">Most NFTs cannot be divided into smaller units or fractions. You will only be able to access a whole digital item not partial.</p>
          </div>
          <div className="col-md-6">
            <p className="leftBorder">These non-fungible tokens are known for their uniqueness. This data is completely accurate, secure and totally distinct.</p>
          </div>
        </div>
      </div>
    </section>
    <section className="industriesSec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h6>Our Development</h6>
            <h2 className="mb-4">Our <span>NFT Development</span> across Various industries</h2>    
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="industriesSec-box">
              <div className="dflex">
                <img src={nftarticon1} />
                <h4>Art</h4>
              </div>
              <p>NFT helps the artists to easily trade their artwork and masterpieces without any data thefts or copyright issues by tokenizing their creation and list in the marketplace.</p>
            </div>
          </div>
          <div className="col-lg-8 col-md-6">
            <div className="industriesSec-box">
              <div className="dflex">
                <img src={nftmusicicon1} />
                <h4>Music</h4>
              </div>
              <p>NFT allows musicians to tokenize their creations, list them in the marketplace, and ultimately eliminate piracy in music. Tokenizing music can help you get more value in the marketplace.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="industriesSec-box">
              <div className="dflex">
                <img src={nftsportsicon1} />
                <h4>Sports</h4>
              </div>
              <p>The NFT for sports allows users to buy and sell sports assets like trading cards, sports video clips, highlights, autographs, sports accessories, and rare assets which can be tokenized.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="industriesSec-box">
              <div className="dflex">
                <img src={nftgamesicon1} />
                <h4>Games</h4>
              </div>
              <p>NFT development easily transfers in-game assets and provides proof of authenticity. Virtual assets in games provides ownership of rare assets, various characters and avatars.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="industriesSec-box">
              <div className="dflex">
                <img src={nftrealestateicon1} />
                <h4>Real Estate</h4>
              </div>
              <p>The users can tokenize their lands in no time and list them in the open marketplace. Here the interested buyers can bid on your land and but the property at a satisfactory amount.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="industriesSec-box">
              <div className="dflex">
                <img src={nftfashionicon1} />
                <h4>Fashion Industry</h4>
              </div>
              <p>Designers can tokenize the fashion accessories in the NFT marketplace where buyers can also verify the ownership of all their designs and fashion items to eliminate the risk of fraud.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="industriesSec-box">
              <div className="dflex">
                <img src={nftphotosicon1} />
                <h4>Photos And Videos</h4>
              </div>
              <p>Turn special clips, rare moments, photos, rare moments that can be tokenized into NFTs. It provides great opportunities for collectors or fans and generate revenue with several benefits.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="industriesSec-box">
              <div className="dflex">
                <img src={nftipoicon1} />
                <h4>IPOs</h4>
              </div>
              <p>NFTs have become significantly popular, and NFT has become a way of offering assuring originality. Just like any other NFT, every asset is authentic, which provides the creator royalty and proof of ownership.</p>
            </div>
          </div>
          <div className="col-lg-8 col-md-6">
            <div className="industriesSec-box">
              <div className="dflex">
                <img src={nftlendingicon1} />
                <h4>Lending And Auction</h4>
              </div>
              <p>NFTs allows users to lend and borrow their NFTs and generate interest or also place a bid at or above the price which is placed by the creators and own it once the auction ends.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="industriesSec-box">
              <div className="dflex">
                <img src={nftdomainicon1} />
                <h4>Domain</h4>
              </div>
              <p>Trading of Blockchain domains is the new popular trend in NFT marketplace. NFTs also work with highly-valued commodities, distinct domain names, on their marketplaces.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="nftmarketplaceSec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h6>Eclipton</h6>
            <h2>NFT Marketplace <span>Development</span></h2>            
          </div>
          <div className="col-lg-6 col-md-12">
            <p>Start your NFT marketplace with the best NFT Marketplace Development Company Create a unique collectibles marketplace with our experts in the popular NFT space. Be an early participant in the NFT revolution and change how digital assets are owned. Shamla Tech is a trusted company known for launching NFT platforms and providing top-notch NFT marketplace solutions.</p>
            <p>The NFT marketplace platform is very essential for business models to trade non-fungible tokens securely. We assist you to develop your innovative NFT marketplace development platform with our salient features. We develop your NFT marketplace platform with multiple blockchain technology. Blockchain technology is the primary core of the security framework that is distributed throughout the platform.</p>
            <div className="buttonSec">
              <a href="#" className="primaryBtn me-2">Get Connect</a>
              <a href="#" className="secondaryBtn ms-2">Free Demo</a>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 text-center">
            <img src={nftstartimg1} style={{ width: '65%' }} />
          </div>          
        </div>
      </div>
    </section>
    <section className="nftchainSec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h6>Single Chain</h6>
            <h2>Single chain <span>Marketplace</span> development</h2>            
          </div>
          <div className="col-md-12">
            <div className="nftchainSec-box">
              <div className="nftchainSec-box-label">1. Storefront</div>
              <div className="nftchainSec-box-label">2. Token Filter/Search</div>
              <div className="nftchainSec-box-label">3. Listings</div>
              <div className="nftchainSec-box-label">4. Biddings & Auctions</div>
              <div className="nftchainSec-box-label">5. Ratings & Recommendations</div>
              <div className="nftchainSec-box-label">6. Reviews & Reputations</div>
              <div className="nftchainSec-box-label">7. Bookmarks/Favorites</div>
              <div className="nftchainSec-box-label">8. User Authentications - Password, 2FA, SSO</div>
              <div className="nftchainSec-box-label">9. Notifications - In App, Email</div>
              <div className="nftchainSec-box-label">10. Cross Platform Listings</div>
              <div className="nftchainSec-box-label">11. Role Based Access Controls</div>
              <div className="nftchainSec-box-label">12. Logs - User Actions, System</div>
              <div className="nftchainSec-box-label">13. Whitelist/Blacklist Management</div>
              <div className="nftchainSec-box-label">14. Statistics/Reports</div>
              <div className="nftchainSec-box-label">15. Application Programming Interface</div>
              <div className="nftchainSec-box-label">16. Option to user to select either Ethereum or BSC blockchain for token minting</div>
              <div className="nftchainSec-box-label">17. ERC 721, ERC 1155 or equivalent</div>
              <div className="nftchainSec-box-label">18. Composables - ability to split a token into multiple sub-tokens and sell them individually</div>
              <div className="nftchainSec-box-label">19. Custodian & Non-Custodian ERC 20 Wallet compatibility to store NFTs</div>
              <div className="nftchainSec-box-label">20. Wallet Connect, Metamask Support</div>
              <div className="nftchainSec-box-label">21. Multisig Wallet Support</div>
              <div className="nftchainSec-box-label">22. Bulk Token Minting</div>
              <div className="nftchainSec-box-label">23. Lazy Minting</div>
              <div className="nftchainSec-box-label">24. Offchain Storage - both IPFS and a dedicated URI</div>
              <div className="nftchainSec-box-label">25. Offchain Metadata - both IPFS and a dedicated URI</div>
              <div className="nftchainSec-box-label">26. Multi-ownership of NFT token - ability to record owners and their shares</div>
              <div className="nftchainSec-box-label">27. Dynamic token metadata fields</div>
              <div className="nftchainSec-box-label">28. Token Provenance/Transaction History</div>
              <div className="nftchainSec-box-label">29. NFT Commerce</div>
              <div className="nftchainSec-box-label">30. Bid/Ask</div>
              <div className="nftchainSec-box-label">31. Transfer</div>
              <div className="nftchainSec-box-label">32. Auction</div>
              <div className="nftchainSec-box-label">33. Royalty</div>
              <div className="nftchainSec-box-label">34. Future Timed Auction</div>
              <div className="nftchainSec-box-label">35. Private Auction</div>
              <div className="nftchainSec-box-label">36. Pricing</div>
              <div className="nftchainSec-box-label">37. Set Price</div>
              <div className="nftchainSec-box-label">38. Reserved Price</div> 
              <div className="nftchainSec-box-label">39. English Auction</div>
              <div className="nftchainSec-box-label">40. Dutch Auction</div>
              <div className="nftchainSec-box-label">41. Timed Auction</div>
              <div className="nftchainSec-box-label">42. Option to users to transact in either fiat or in crypto</div>
              <div className="nftchainSec-box-label">43. Cart</div>
              <div className="nftchainSec-box-label">44. Order Status/History</div>
              <div className="nftchainSec-box-label">45. Asset Order Book</div>
              <div className="nftchainSec-box-label">46. Algorithmic Trade Buy/sell P2P</div>
              <div className="nftchainSec-box-label">47. API Driven Trade</div> 
              <div className="nftchainSec-box-label">48. Real Time P&L</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="nftfeatureSec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h6>Our Features</h6>
            <h2>Security <span>Features</span> of NFT platform</h2>            
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="nftfeatureSec-box">
              <img src={chooseimg1} />
              <h4>Encryption</h4>
              <p>The platform encrypts details in transit based on demand with customisability to ensure data integrity is maintained.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="nftfeatureSec-box">
              <img src={chooseimg1} />
              <h4>Firewall, DOS, DDOS</h4>
              <p>Highly secured platform with Firewall, DOS, DDOS, KYC and AML user verification protecting users from scams and unauthorized activities.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="nftfeatureSec-box">
              <img src={chooseimg1} />
              <h4>Multi-Factor Authentication</h4>
              <p>Get topnotch security to the funds and transactions with 2 or multi factor authentication enabled for both the admin and the users.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="nftfeatureSec-box">
              <img src={chooseimg1} />
              <h4>Security Layer</h4>
              <p>Launch INO platform that facilitates totally safe, automated is evaluated by different security teams for the user's trustworthiness.</p>
            </div>
          </div>
          <div className="col-lg-8 col-md-6">
            <div className="nftfeatureSec-box">
              <img src={chooseimg1} />
              <h4>Blacklist Management</h4>
              <p>Managing and blacklisting users based on their IP address, geo-location, suspicious conduct, and other factors with an easy-to-use dashboard.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="nftmultiSec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h6>Functionalities</h6>
            <h2>Multichain <span>NFT Marketplace</span> Functionalities List</h2>            
          </div>
          <div className="col-md-4">
            <div className="nftmultiSec-box">
              <h4>Store Front</h4>
              <ul>
                <li>Token Filter / Search</li>
                <li>Listings</li>
                <li>Biddings & Auctions</li>
                <li>Rating & Recommendations</li>
                <li>Reviews & Reputations</li>
                <li>Bookmark / Favorites</li>
                <li>User Authentications - Password</li>
                <li>Notifications - In App, Email</li>
                <li>Multi Platform Listings</li>
                <li>Role Based Access Controls</li>
                <li>Logs - User Actions, System</li>
                <li>Whitelist Management</li>
                <li>Satistics / Reports</li>
                <li>API Integration</li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nftmultiSec-box">
              <h4>NFT Related</h4>
              <ul>
                <li>Option to user to select either EVM blockchains for token minting</li>
                <li>ERC 721 development</li>
                <li>Non-Custodian Wallet compatibility to store NFTs</li>
                <li>Wallet Connect, Metamask Support</li>
                <li>Top up Wallet Support</li>
                <li>Bulk Token Minting</li>
                <li>Lazy Minting</li>
                <li>Offchain NFT Storage - both IPFS and a dedicated URI</li>
                <li>Offchain Metadata - both IPFS and a dedicated URI</li>
                <li>Dynamic Token metadata fields</li>
                <li>Token Provenance / Transaction History</li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nftmultiSec-box">
              <h4>Multichain Front</h4>
              <ul>
                <li>Separte Dashboard</li>
                <li>Network Management</li>
                <li>Specific wallet connection to the network</li>
                <li>Admin</li>
                <li>Activity Logs</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="nftmultiSec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h6>Functionalities</h6>
            <h2><span>NFT Marketplace</span> Multichain functionalities list</h2>            
          </div>
          <div className="col-md-4">
            <div className="nftmultiSec-box">
              <h4>Admin Portal</h4>
              <ul>
                <li>Dashboard</li>
                <li>NFT Management</li>
                <li>Bid / Auction Management</li>
                <li>Assets Management</li>
                <li>User Management</li>
                <li>Personalization</li>
                <li>Minting, Bidding, Auction on behalf of end user</li>
                <li>Logs - Transactions, System</li>
                <li>Usage / Billing</li>
                <li>Sttings</li>
                <li>Events & Notifications - Security, System, Requests, Updates</li>
                <li>Reports</li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nftmultiSec-box">
              <h4>NFT Commerce</h4>
              <ul>
                <li>Bid / Ask</li>
                <li>Transfer</li>
                <li>Auction</li>
                <li>Royalty</li>
                <li>Future Timed Auction</li>
                <li>Pricing</li>
                <li>Set Price</li>
                <li>Reserved Price</li>
                <li>English Auction</li>
                <li>Option to transact in either fiat or in crypto</li>
                <li>Cart</li>
                <li>Order Status / History</li>
              </ul>
            </div>
          </div>          
          <div className="col-md-4">
            <div className="nftmultiSec-box">
              <h4>Asset Management</h4>
              <ul>
                <li>Asset Order Book</li>
                <li>Algorithmic Trade</li>
                <li>API Driven Trade</li>
                <li>Real Time P&L</li>
                <li>Monthly Reports</li>
              </ul>
            </div>
          </div>
          </div>
        </div>
      </section>
      <section className="nftwhySec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h6>Our Support</h6>
            <h2>Why Choose Us?</h2>            
          </div>
            <div className="col-lg-4 col-md-6">
              <div className="nftwhySec-box">
                <h4>Exceptional Project Quality</h4>
                <p>We excel in delivering superior project quality. Our streamlined processes ensure efficiency without unnecessary delays.</p>
                <span>1</span>
              </div>
              </div>
              <div className="col-lg-8 col-md-6">
              <div className="nftwhySec-box">
                <h4>Comprehensive Solutions</h4>
                <p>Count on us for end-to-end solutions that tailor the platform precisely to your business needs. Our skilled developers are adaptable and can customize solutions to lead the market.</p>
                <span>2</span>
              </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="nftwhySec-box">
                  <h4>Client-Centric Dedication</h4>
                  <p>We're highly committed to fulfilling our clients' requirements, ensuring that your needs are our top priority.</p>
                  <span>3</span>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="nftwhySec-box">
                  <h4>Scalability for Efficiency</h4>
                  <p>We guarantee scalability to enhance operational efficiency, adapting as your business grows.</p>
                  <span>4</span>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="nftwhySec-box">
                  <h4>Swift Delivery</h4>
                  <p>Expect rapid delivery of robust software that precisely meets your target audience's needs.</p>
                  <span>5</span>
                </div>
              </div>
              <div className="col-lg-8 col-md-6">
                <div className="nftwhySec-box">
                  <h4>Experienced Professionals</h4>
                  <p>Our team consists of experienced and professional experts in the field.</p>
                  <span>6</span>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="nftwhySec-box">
                  <h4>Round-the-Clock Support</h4>
                  <p>We provide end-to-end, round-the-clock support to address any concerns or issues promptly.</p>
                  <span>7</span>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Nftmarketplace;
